<template>
  <a-menu
    :mode="platformType === 'console-scrm' ? 'inline' : 'vertical'"
    :open-keys="openKeys"
    :class="`nav ${menuCollapsed ? 'menu-collapsed' : ''}`"
    @click="selectItem"
    :selectedKeys="current"
    @openChange="onOpenChange"
  >
    <template v-for="item in menus">
      <a-menu-item v-if="!item.children || !item.children.length" :key="item.action || item.menuId">
        <menu-icon :icon="item.icon" />
        <a class="sider-menu-title" @click="refreshStop" :href="`/micro-${item.action}`">
          {{ item.title }}
        </a>
      </a-menu-item>
      <sub-menu
        v-else
        :key="item.action || item.menuId"
        :route="item"
        :statisticsEnum="filterStatisticsEnum"
        :openKeys="openKeys"
        :menusStatistics="$store.state.menusStatistics"
      />
    </template>
  </a-menu>
</template>

<script>
import { mapState } from 'vuex'
import subMenu from './subMenu.vue'
import platformEnum from '@/utils/platformEnum'
import menuIcon from './menuIcon.vue'

export default {
  data() {
    return {
      current: [],
      openKeys: [],
      // 代办和菜单关联的映射
      statisticsEnum: {
        'contract/applyCustomList': 'customerNum', // 客户申请
        // 'provider#/contract/applyAgentList': 'agentNum', // 渠道申请
        'invoice/invoiceList': 'invoiceNum', // 发票开具
        'payment/topUpManage/topUp/table': 'rechargeNum', // 充值管理
        'payment/topUpManage/refund/table': 'puzzleNum', // 退款/调账
        'provider/contract-apply': ['customerNum', 'agentNum'], // 合同审批（一级不做跳转，仍为旧路径）
        'provider/invoice-manager': 'invoiceNum', // 发票管理（一级不做跳转，仍为旧路径）
        'provider/topUpReconciliation': ['rechargeNum', 'puzzleNum', 'attachServiceChargeFlow'], // 充值调账（一级不做跳转，仍为旧路径）
        'provider/serviceCompany': 'companyApplyNum', // 落地管理（一级不做跳转，仍为旧路径）
        'provider-manage/serviceCompany/joinRequestList': 'companyApplyNum', // 入驻申请（二级）
        'risk/hgt/customer': 'handleHgt', // 销售端-合规通
        'risk/hgt/customer/index': 'handleHgt', // 销售端-客户概览
        'provider/taxManagement': ['waitApprovalNum', 'taxOrderWaitNum'], // 报税管理（一级不做跳转，仍为旧路径）
        'provider/accounting': ['paymentOrderNum', 'agentSettleAuditFlow'], // 结算管理（一级不做跳转，仍为旧路径）
        'provider/paymentManage': ['paymentOrderNum', 'agentSettleAuditFlow'], // 付款管理（二级不做跳转，仍为旧路径）
        'settlement/settlement/taxReturn/list': 'waitApprovalNum', // 报税单列表待办数量
        'settlement/settlement/paymentOrder/list': 'paymentOrderNum', // 付款单列表待办数量
        'settlement/rebatePayment/index': 'agentSettleAuditFlow', // 返佣付款申请列表
        'admin/main/accountManager': 'attachServiceChargeFlow', // 资金服务
        'settlement/settlement/accounting/overcharge': 'attachServiceChargeFlow', // 多收服务费处理流程,
        'settlement/settlement/taxOrder/list': 'taxOrderWaitNum', // p端完税列表待办
        // open端红点
        'risk/review': 'openHgtRedot', // 合规通
        'risk/review/ComplianceRate': 'openHgtRedot', // 合规任务
      },
    }
  },
  components: { subMenu, menuIcon },
  computed: {
    ...mapState(['menuCollapsed', 'menusStatistics', 'platformType', 'menus']),
    // 当前高亮
    // current() {
    //   return [this.$route.fullPath.substring(7)]
    // },
    // 所有路由的
    rootMenuKeys() {
      return this.menus.map((item) => item.action || item.menuId)
    },
    // 过滤掉没有显示的菜单
    filterStatisticsEnum() {
      const dots = {}
      const menuString = JSON.stringify(this.menus)
      Object.keys(this.statisticsEnum).forEach((key) => {
        if (menuString.indexOf(key) > -1) {
          dots[key] = this.statisticsEnum[key]
        }
      })
      return dots
    },
  },
  watch: {
    menus() {
      this.openKeys = [this.menus[0].action]
    },
    $route() {
      // 获取缓存的高亮菜单
      const currentRename = localStorage.getItem('currentRename')
      if (currentRename) {
        this.current = [currentRename]
        return
      }
      const path = this.$route.fullPath.substring(7)
      this.current = [path]
    },
  },
  methods: {
    async selectItem({ key, keyPath }) {
      if (this.platformType === 'console-scrm') {
        const isScrmRegister = window.localStorage.getItem('isScrmRegister') || ''
        const scrmUserId = window.localStorage.getItem('userId') || ''
        if (isScrmRegister === 'Signed') {
          this.setOpenKeys({ key, keyPath })
          return
        }
        try {
          const { data } = await this.$get('/api/scrm/manage/agent-register/check-current-agent') || { data: [] }
          const obj = data.find((item) => item.operateEnum === 1) || {}
          window.localStorage.setItem('isScrmRegister', obj.contractSignStatus || '')
          const channels = window.localStorage.getItem('channels') || ''
          // 存在渠道 但是获取info接口又没有数据，老数据用户没有迁移过来
          if (channels && Object.keys(obj).length === 0) {
            window.localStorage.setItem('isScrmRegister', 'Signed')
            this.setOpenKeys({ key, keyPath })
            return
          }
          // 登录的userId是渠道负责人 是渠道创建人才去判断用户是否签约
          if (scrmUserId && obj.agentCompanyContactUserId && scrmUserId === String(obj.agentCompanyContactUserId)) {
            if (obj.contractSignStatus !== 'Signed' || (obj.contractSignStatus === 'Signing' && !obj.esignContractUrl)) {
              if (key === 'scrm/system/channelInfo') {
                this.setOpenKeys({ key, keyPath })
              } else {
                const { origin } = window.location
                window.location.href = `${origin}/micro-scrm/page`
              }
            } else {
              this.setOpenKeys({ key, keyPath })
            }
          } else {
            window.localStorage.setItem('isScrmRegister', 'Signed')
            this.setOpenKeys({ key, keyPath })
          }
        } catch (error) {
          console.log(error)
        }
        return
      }
      this.setOpenKeys({ key, keyPath })
    },
    setOpenKeys({ key, keyPath }) {
      const path = this.$route.fullPath
      const currentKey = `/micro-${key}`
      if (currentKey !== path) {
        console.log('new path', currentKey)
        window.history.pushState({}, '', currentKey)
      }
      this.openKeys = keyPath
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1,
      )
      if (this.rootMenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    refreshStop(e) {
      e.preventDefault()
    },
  },
  created() {
    // 初始化高亮
    this.current = [this.$route.fullPath.substring(7)]
  },
}
</script>
<style lang="scss" scoped>
.ant-menu-item, 
/deep/ .ant-menu-submenu-title, 
.ant-menu-item-group-title, 
/deep/ .ant-menu-item>a{
  color: $menu-font-color;
  background: $menu-bg-color;
}
.ant-menu-item > a {
  color: $menu-font-color;
  &:hover {
    color: $menu-font-hover-color;
  }
}
.ant-menu {
  border-right: 0;
  background: $menu-bg-color;
  /deep/ .menu-title {
    margin-left: 8px;
  }
}
.sider-menu-title {
  margin-left: 5px;
  display: inline;
  &:hover {
    color: $menu-font-hover-color !important;
  }
}
.menu-collapsed, .ant-menu-inline-collapsed {
  /deep/ .menu-title {
    display: none;
  }
  /deep/ .sider-menu-title {
    display: none;
  }
  /deep/ .ant-menu-submenu, .ant-menu-submenu-active {
    .ant-menu-submenu-arrow{
      display: none;
    }
  }
}
.ant-menu-item-active {
  .sider-menu-title {
    color: $menu-font-hover-color !important;
  }
}
.ant-menu-item-selected {
  background-color: $menu-bg-active-color !important;
  .sider-menu-title {
    color: $menu-font-active-color;
    background-color: $menu-bg-active-color !important;
    &:hover {
      color: $menu-font-active-color !important;
    }
  }
  .svg-icon {
    color: $menu-font-active-color;
  }
}
</style>
