<template>
  <a-sub-menu :key="route.action || route.menuId" v-bind="$props" v-on="$listeners">
    <span
      slot="title"
      :class="`submenu-title-wrapper`"
      :key="route.action || route.menuId"
    >
      <menu-icon :icon="route.icon" />
      <a class="sider-menu-title" href="javascript:;"> {{ route.title }}</a>
      <!-- 这里需要显示客户代办，只有合同审批（客户申请，渠道申请），充值调账（充值管理，退款），发票管理（发票工具）
        当菜单合起来后显示，展开不显示
       -->
      <transition name="fade">
          <a-badge
            v-if="statisticsEnum[route.action]"
            :count="count"
          />
      </transition>
    </span>
    <template v-for="item in route.children">
      <a-menu-item
        v-if="!item.children || !item.children.length"
        :key="item.action || item.menuId"
      >
        <a class="sider-menu-title" @click="refreshStop" :href="`/micro-${item.action}`">
          {{ item.title }}</a
        >
        <!-- 这里需要显示子菜单的代办数 -->
        <a-badge
          v-if="statisticsEnum[item.action]"
          :count="menusStatistics[statisticsEnum[item.action]]"
        />
      </a-menu-item>
      <sub-menu
        v-else
        :route="item"
        :key="item.action || item.menuId"
        :statisticsEnum="statisticsEnum"
        :openKeys="openKeys"
        :menusStatistics="menusStatistics"
      ></sub-menu>
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { mapState } from 'vuex'
import platformEnum from '@/utils/platformEnum'
import menuIcon from './menuIcon.vue'

export default {
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  components: {
    menuIcon,
  },
  props: {
    ...Menu.SubMenu.props,
    route: {
      type: Object,
    },
    statisticsEnum: {
      type: Object,
    },
    menusStatistics: {
      type: Object,
    },
    openKeys: {
      type: Array,
    },
  },
  computed: {
    ...mapState(['platformType']),
    count() {
      let num = 0
      if (Array.isArray(this.statisticsEnum[this.route.action])) {
        this.statisticsEnum[this.route.action].forEach((key) => {
          num += Number(this.menusStatistics[key] || 0)
        })
      } else {
        num = this.menusStatistics[this.statisticsEnum[this.route.action]]
      }
      return num
    },
  },
  data() {
    return {
      // 菜单key的对应代办数key
    }
  },
  methods: {
    refreshStop(e) {
      e.preventDefault()
    },
  },
}
</script>

<style lang="scss" scoped>
.submenu-title-wrapper,
.ant-menu-item {
  /deep/ .ant-badge-count {
    box-shadow: none;
    margin-left: 6px;
    height: 16px;
    line-height: 16px;
    margin-top: -4px;
  }
}
.sider-menu-title {
  margin-left: 5px;
  display: inline;
  text-decoration: none;
  color: $menu-font-color;
}
// 二级菜单
.ant-menu-submenu {
  background-color: $menu-bg-color !important;
}
.ant-menu-submenu-content {
  background-color: $menu-bg-color !important;
  .ant-menu-item {
    color: $menu-font-color !important;
    background-color: $menu-bg-color;
  }
  
  .sider-menu-title {
    &:hover {
      color: $menu-font-hover-color !important;
    }
  }
  .ant-menu-item-selected {
    background-color: $menu-bg-active-color !important;
    .sider-menu-title {
      color: $menu-font-active-color !important;
    }
  }
}
// 选择的菜单
.ant-menu-submenu-selected {
  background-color: $menu-bg-active-color !important;
  /deep/ .ant-menu-submenu-title {
    background-color: $menu-bg-active-color !important;
  }
  .sider-menu-title {
    color: $menu-font-active-color;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.ant-menu-submenu > .ant-menu {
  background-color: $menu-bg-color !important;
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  background: $menu-font-color !important;
}
.ant-menu-submenu-popup {
  background-color: $menu-bg-color !important;
}
</style>
