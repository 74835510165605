<template>
  <a-breadcrumb v-if="showBreadCrumb">
    <a-breadcrumb-item v-for="route in currentRoutes" :key="route.action">
      <span class="breadItem">
        {{ route ? route.title : '' }}
      </span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentRoutes: [],
    }
  },
  computed: {
    ...mapState(['platformType', 'menus']),
    showBreadCrumb() {
      // isShowBreadCrumbOnPath 放在路由参数里面定义是否显示面包屑，false：不显示，true：显示；不传默认显示
      const { query } = this.$route
      const notShowOntenantHome = this.$route.path.indexOf('/micro-tenant/dashboard') === -1
      const notShowOnproviderHome = this.$route.path.indexOf('/micro-provider-manage/dashboard') === -1
      const notShowOnsalesHome = this.$route.path.indexOf('/micro-agent/home') === -1
      const notShowOnopeninHome = this.$route.path.indexOf('/micro-open/dashboard') === -1
      console.log(this.platformType, '------------------------')
      return (
        ((this.platformType === 'console-provider' && notShowOnproviderHome)
          || (this.platformType === 'console-tenant' && notShowOntenantHome)
          || (this.platformType === 'console-agent' && notShowOnsalesHome)
          || (this.platformType === 'console-company' && notShowOnopeninHome))
        && !(query.isShowBreadCrumbOnPath === 'false')
      )
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        if (route && this.showBreadCrumb) {
          const { fullPath } = route
          // 去掉带的参数
          const currentPath = fullPath.replace('/micro-', '').split('?')[0]
          let routes = []
          routes = this.findNodeAndAncestors(this.menus, currentPath)
          // if (!routes.length) {
          //   const historyRoutes = window.sessionStorage.getItem('breadcrumbs')
          //   routes = JSON.parse(historyRoutes || '[]')
          // const prevPath = prevRoute ? prevRoute.fullPath.replace('/micro-', '') : ''
          // if (prevPath && routes[routes.length - 1]?.title && currentPath !== prevPath) {
          //   routes.push({
          //     action: currentPath,
          //     title: ''
          //   })
          // }
          // }
          this.currentRoutes = routes
          // window.sessionStorage.setItem('breadcrumbs', JSON.stringify(routes))
        }
      },
    },
    menus: {
      handler(newVal) {
        const route = this.$route
        if (route) {
          const currentPath = route.fullPath.replace('/micro-', '').split('?')[0]
          let routes = []
          console.time('Timer')
          routes = this.findNodeAndAncestors(newVal, currentPath)
          console.timeEnd('Timer')
          this.currentRoutes = routes
        }
      },
    },
  },
  created() {},
  methods: {
    findNodeAndAncestors(tree, id) {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of tree) {
        if (node.action === id) {
          return [node]
        } if (node.children?.length > 0) {
          const childResult = this.findNodeAndAncestors(node.children, id)
          if (childResult.length > 0) {
            return [node, ...childResult]
          }
        }
      }
      return []
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-breadcrumb {
  padding: 20px 0 0 20px;
}

//.breadItem:hover {
//  color: $primary-color;
//}

.ant-breadcrumb > span:last-child .breadItem {
  color: #2a2a2a !important;
}
</style>
